<template>
  <div>
    <b-row
      center
      class="d-flex"
      style="padding-top:20%"
    >
      <b-col
        md="6"
        offset-md="3"
      >
        <b-card>
          <b-card-body
            class="text-center"
          >
            <h5>Bitte haben Sie etwas Geduld, während die Daten geladen werden.</h5>
            <br>
            <br>
            <b-spinner
              variant="primary"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BSpinner,
  BCard,
  BCardBody,
  BRow,
  BCol,
} from 'bootstrap-vue'

import {
  onUnmounted,
} from '@vue/composition-api'

import router from '@/router'

import store from '@/store'
import homeStoreModule from '../homeStoreModule'

export default {
  components: {
    BSpinner,
    BCard,
    BCardBody,
    BRow,
    BCol,
  },
  setup() {
    window.$cookies.remove('userData')
    window.$cookies.remove('partnerData')
    window.$cookies.remove('accessToken')
    window.$cookies.remove('refreshToken')

    let partner = ''
    let redirect = '/register'

    if (router.currentRoute.name === 'redirect-salzburger-lagerhaus') {
      partner = 'rvs'
      redirect = '/register'
    } else if (router.currentRoute.name === 'redirect-genol') {
      partner = 'genol'
      redirect = '/register'
    } else if (router.currentRoute.name === 'redirect-raiffeisen-leasing_genol') {
      partner = 'raiffeisen-leasing_genol'
      redirect = '/register'
    } else if (router.currentRoute.name === 'redirect-raiffeisen_genol') {
      partner = 'raiffeisen_genol'
      redirect = '/register'
    }

    console.log('router.currentRoute.name', router.currentRoute.name)
    console.log('partner', partner)
    console.log('redirect', redirect)

     window.$cookies.set('partner', partner, Infinity)
    
    const appIdElem = document.getElementById('app')
    let backgroundImage = ''
    setTimeout(() => {
      if (typeof (window.$cookies.get('partner')) !== 'undefined') {
        backgroundImage = require(`@/assets/images/partner/${partner}/backgorund/auth.jpg`)
        appIdElem.style.backgroundImage = `url('${backgroundImage}')`

        if ((window.$cookies.get('partner') === 'raiffeisen-leasing_genol') || (window.$cookies.get('partner') === 'raiffeisen_genol')) {
          appIdElem.style.backgroundPosition = 'top right'
        }
      }
    }, 500)
   
    const APP_HOME_STORE_MODULE_NAME = 'app-home'

    // Register module
    if (!store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.registerModule(APP_HOME_STORE_MODULE_NAME, homeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.unregisterModule(APP_HOME_STORE_MODULE_NAME)
    })

    setTimeout(() => {
      store
        .dispatch(`${APP_HOME_STORE_MODULE_NAME}/getPartnerData`, { partnerKey: partner })
        .then(response => {
          if (response.status === 200) {
            //  window.$cookies.set('partnerData', response.data, Infinity)
            window.$cookies.set('partnerData', response.data, Infinity, null, null, true, 'None')
            document.title = response.data.htmlPageTitle
            document.querySelector("link[rel*='icon']").href = `favicon_${response.data.key}.ico`

            //  window.location.href = redirect
            router.push(redirect)
          } else {
            // . fallback to defaults
            store
              .dispatch(`${APP_HOME_STORE_MODULE_NAME}/getPartnerData`, { partnerKey: 'ladestellenbonus' })
              .then(responseFallback => {
                if (responseFallback.status === 200) {
                  //  window.$cookies.set('partnerData', responseFallback.data, Infinity)
                  window.$cookies.set('partnerData', responseFallback.data, Infinity, null, null, true, 'None')
                  document.title = responseFallback.data.htmlPageTitle
                  document.querySelector("link[rel*='icon']").href = `favicon_${responseFallback.data.key}.ico`
                  router.push(redirect)
                }
              })
          }
        })
    }, 1500)
  },
}
</script>
